<template>
  <div>
    <div class="title">{{ $t("financingInformation") }}</div>
    <el-form ref="form" :model="form" label-width="210px">
      <el-form-item :label="$t('Orderamount')">
        <span>{{ form.orderAmount | toThousandFilterTwo }} {{ $t("millionDollars") }}</span>
      </el-form-item>
      <el-form-item :label="$t('financingAmountRatio')" label-width="250px">
        <span>{{ form.financingDiscount ? form.financingDiscount + '%' : '0%' }}</span>
      </el-form-item>
      <el-form-item :label="$t('financingAmount')">
        <span>
          <!-- {{ Number(form.askAmount).toFixed(2) }} -->
          {{ form.financingAmount | toThousandFilterTwo }}
          {{ $t("millionDollars") }}
        </span>
      </el-form-item>
      <el-form-item :label="$t('repaymentMethod')" label-width="250px">
        <span>{{ form.repaymentMethod }}</span>
      </el-form-item>
      <el-form-item :label="$t('dateOfIssuance')">
        <span>{{ form.shipmentDate }}</span>
      </el-form-item>
      <el-form-item :label="$t('deadlinefForFund')" label-width="250px">
        <span>{{ form.deadlineFundRaising }}</span>
      </el-form-item>
      <el-form-item :label="$t('promisedPaymentDateAgain')">{{form.promisedPaymentDate}}</el-form-item>
      <el-form-item :label="$t('borrowingCycle')" label-width="250px">
        <span>{{ form.borrowingCycle }}&nbsp;{{ $t("day") }}</span>
      </el-form-item>
      <el-form-item :label="$t('financingAgent')">
        <span>{{ form.financingAgent }}</span>
      </el-form-item>
      <el-form-item :label="$t('phoneNumber')" label-width="250px">
        <span>{{ form.phoneNumber }}</span>
      </el-form-item>
      <el-form-item :label="$t('brandOwner')" style="width: 100%;" v-if="form.boUser">
        <span>{{ form.boUser && form.boUser.username }}</span>
      </el-form-item>
      <el-form-item :label="$t('formTitleI18n.ContractReferenceNumber')">
        <span>{{ form.contractNumber }}</span>
      </el-form-item>
      <el-form-item :label="$t('financingPurpose')" style="width: 100%;">
        <span>{{ form.fiancingPurpose }}</span>
      </el-form-item>
      <el-form-item :label="$t('receivableContract')" style="width: 100%;">
        <div v-for="(item,index) in fileData" :key="index">
          <el-link
              class="link-style"
              :underline="false"
              type="primary"
              @click="goLink(item)"
          >{{ item.split("/")[item.split("/").length - 1] }}</el-link>
        </div>
      </el-form-item>
    </el-form>
    <div class="title">{{ $t("collectionInformation") }}</div>
    <el-form ref="form" :model="form" style="width: 100%;" label-width="210px">
        <div>
            <el-form-item :label="$t('bankOfCityCountry')" label-width="210px">
                <span v-if="form.areaId">{{ getAreaName(form.areaId) }}</span>
                <span v-else></span>
            </el-form-item>
        </div>
        <el-form-item :label="$t('bankOfDeposit')">
            <div class="flex-row">
                <el-image v-if="form.receivingBankIcon" :src="form.receivingBankIcon" style="width: 18px; height: 18px; margin-right: 10px;"></el-image>
                <span v-if="language==='zh-cn'">{{ form.receivingBank }}</span>
                <span v-else>{{ form.receivingBankEn || form.receivingBank }}</span>
            </div>
        </el-form-item>
        <el-form-item :label="$t('companyCreate.swiftCode')" label-width="250px">
            <span>{{ form.swiftCode }}</span>
        </el-form-item>
      <el-form-item :label="$t('companyCreate.bankNumber')">
        <span>{{ form.bankAccountNumber }}</span>
      </el-form-item>
      <el-form-item :label="$t('companyCreate.bankAccount')" label-width="250px">
        <span>{{ form.accountHolderName }}</span>
      </el-form-item>
    </el-form>
    <div style="text-align: center; margin-top: 30px">
      <el-button class="btn-black" v-on:click="close">
        {{
        $t("btnI18n.Close")
        }}
      </el-button>
    </div>
  </div>
</template>

<script>
// import { Decrypt } from "@/utils/crypto";
import filter from "@/filters";
import {getObjByKeyValue} from "@/utils";

export default {
  data() {
    return {
      tableData: [],
      form: {},
      extraData: {},
      fileData: [],
        // bank update
        optionsCountry: [],
        // bank update
    };
  },
  created() {
    this.getAreaList();
    this.getDetail();
  },
  methods: {
      // bank update
      getAreaList() {
          let _this = this;
          this.optionsCountry = [];
          this.$axios.get("/v1/bank/area").then(res => {
              if (!res.code) {
                  _this.optionsCountry = res.data;
              }
          });
      },
      getAreaName(value) {
          if (!value) return "";
          if (!this.optionsCountry.length) return "";
          if (this.language === "zh-cn") {
              return getObjByKeyValue(this.optionsCountry, "code", Number(value)).cnName;
          } else {
              return getObjByKeyValue(this.optionsCountry, "code", Number(value)).enName;
          }
      },
      // bank update
    goLink(url) {
      if (/.*(\.png|\.jpg|\.jpeg|\.gif|\.PNG|\.JPG|\.JPEG|\.GIF)$/.test(url)) {
        const image = new Image();
        image.src = url;
        const imgWindow = window.open(url);
        imgWindow.document.write(image.outerHTML);
      } else if (/.*(\.pdf|\.PDF)$/.test(url)) {
          let routeData = this.$router.resolve({
              query: {url:url},
              path:'/reviewPDF'
          });
          window.open(routeData.href, '_blank');
      } else {
          window.open(url);
      }
    },
    getDetail() {
      let _this = this;
      if (!this.$route.query.id) return false;
      this.form.id = this.$route.query.id;
      this.$axios.get("/v1/supplier/financ-order", { params: { id: this.$route.query.id } }).then(result => {
        if (result) {
          _this.form = result.data;
          _this.form.orderAmount = Number(_this.form.orderAmount) / this.$enums.UNIT_MILLION;
          _this.form.financingAmount = Number(_this.form.financingAmount) / this.$enums.UNIT_MILLION;
          // _this.form.financingDiscount = Number(_this.form.financingDiscount) / 100;
          _this.form.repaymentMethod = filter.repaymentMethod(_this.form.repaymentMethod);
          if (_this.form.orderFile.indexOf('[') === -1) {
            _this.fileData.push(_this.form.orderFile);
          } else {
            _this.fileData = JSON.parse(_this.form.orderFile);
          }
        }
      });
    },
    close() {
      this.$router.push(`/financing-list`);
    }
  },
    computed: {
        language() {
            return this.$store.getters['auth/language'];
        }
    },
    watch: {
        language() {
            console.log(this.language);
        }
    }
};
</script>

<style lang="scss" scoped>
.el-form-item {
  width: 550px;
  display: inline-block;
  margin-bottom: 0px;
}
.link-style {
  display: block;
  max-width: 200px;
}
// .el-form-item__content {
//   margin-left: 0px !important;
// }
</style>
